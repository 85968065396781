const {AffindaCredential, AffindaAPI} = require("@affinda/affinda");
const credential = new AffindaCredential(AFFINDA_KEY)
const client = new AffindaAPI(credential)

const convert =  {
    init() {
        this.convertCV();
    },
    convertCV(){
        $(document).on('change', '#cv_file', function () {
            var picsize = (this.files[0].size);
            $(".btn-step-2").attr("disabled", true);
            $(".btn-step-2").addClass('wait');
            var ext = $(this).val().split('.').pop().toLowerCase();
            if (picsize > 0) {
                if (picsize > 5120000) {
                    $('#cv-desc').removeClass('d-none');
                    toastr.error("File should not exceed 5Mb.");
                    $('#file_name').text('');
                    $('#cv_file').val("");
                    flag = 0;
                }else{
                    flag = 1;
                }
                
                if ($.inArray(ext, ['pdf', 'docx']) == -1) {
                    $('#cv-desc').removeClass('d-none');
                    toastr.error("Incorrect format: pdf, docx.");
                    $('#file_name').text('');
                    $('#cv_file').val("");
                    check = 0;
                } else {
                    check = 1;
                }
    
                if (check == 1 || flag == 1) {
                    $('#file_name').text(this.files[0].name);
                }
            }
            if(!check || !flag){
                return false;
            }
            client.createResume({file: this.files[0]}).then((result) => {
                $('#cv_convert').val(JSON.stringify(result.data));
                $(".btn-step-2").removeAttr("disabled");
                $(".btn-step-2").removeClass('wait');
                // console.log(JSON.stringify(result.data));
            }).catch((err) => {
                console.error(err);
            });
            
        })
        // $(document).on('change', '#talent_file', function () {
        //     $(".btn-edit-account").attr("disabled", true);
        //     $(".btn-edit-account").addClass('wait');
        //     client.createResume({file: this.files[0]}).then((result) => {
        //         $('#cv_convert').val(JSON.stringify(result.data));
        //         $(".btn-edit-account").removeAttr("disabled");
        //         $(".btn-edit-account").removeClass('wait');
        //         console.log(JSON.stringify(result.data));
        //     }).catch((err) => {
        //         console.error(err);
        //     });
            
        // })
        function convertCV(file, buttonSave, inputResult){
            $(buttonSave).attr("disabled", true);
            $(buttonSave).addClass('wait');
            client.createResume({file: file}).then((result) => {
                $(inputResult).val(JSON.stringify(result.data));
                $(buttonSave).removeAttr("disabled");
                $(buttonSave).removeClass('wait');
                // console.log(JSON.stringify(result.data));
            }).catch((err) => {
                // console.error(err);
            });
        }
        $(document).on('change', '#talent_file', function() {
            let btn = $(this);
            let file = this.files[0];
            checkUploadFiles(Array.from(this.files))
                .then(function(r) {
                    convertCV(file, '.btn-edit-account', '#cv_convert')
                    toastr.options.positionClass = 'toast-top-right';
                    toastr.success(r.message);
                    let str = (r.number > 1) ? `${r.number} files` : `${r.number} file`
                    $('.success_documents').text('File uploaded successfully!')
                    $('#cv-desc').addClass('d-none');
                    $('#cv-label').text('Change File');
                    $('.failed_documents').text('');
                    $('#cv-label').parent().css('color', 'grey');
                    $('#cv-label').parent().css('background', 'white');
                    $('#cv-label').parent().css('border', '1px solid grey');
                    var limit = 18;
                    var line = file.name;
                    if (line.length > limit) {
                        line = line.substr(0, limit) + ".....";
                        $('#file_name').text(line);
                    }
                    check = 0;

                })
                .catch(function(e) {
                    btn.val('');
                    $('#cv-label').parent().css('color', 'white');
                    $('#cv-label').parent().css('background', 'black');
                    $('#cv-label').parent().css('border', '1px solid black');
                    $('.success_documents').text('')
                    $('#cv-label').text('Upload');
                    $('#file_name').text('');
                    $('#cv-desc').removeClass('d-none');
                    $('.failed_documents').text('Upload failed !')
                    toastr.options.positionClass = 'toast-top-right';
                    toastr.error(e.message);
                    check = 0;
                })
        });
    }
    
}

$(function (){
    convert.init(); 
})

